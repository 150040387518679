import Head from 'next/head'
import PropTypes from 'prop-types'
import { sanitize } from 'isomorphic-dompurify'
import cloneDeepWith from 'lodash/cloneDeepWith'

const sanitizeCustomiser = (value) => {
  if (typeof value === 'string') {
    return sanitize(value)
  }

  // Returning undefined to lodash means that it uses the original property value
  return undefined
}
const recursiveSanitize = (properties) =>
  cloneDeepWith(properties, sanitizeCustomiser)

const JsonLdHeadScript = ({ properties, scriptKey }) => {
  // As we're using dangerouslySetInnerHTML we could be vulnerable to an XSS attack through
  // any event properties... so sanitize the values to prevent this.
  const sanitizedProperties = recursiveSanitize(properties)

  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line xss/no-mixed-html
          __html: JSON.stringify(sanitizedProperties),
        }}
        key={scriptKey}
      />
    </Head>
  )
}

JsonLdHeadScript.propTypes = {
  properties: PropTypes.object.isRequired,
  scriptKey: PropTypes.string.isRequired,
}

export { recursiveSanitize }
export default JsonLdHeadScript
