import PropTypes from 'prop-types'
import getConfig from 'next/config'
import arrayHasData from 'lib/arrayHasData'
import JsonLdHeadScript from './JsonLdHeadScript'

const { publicRuntimeConfig } = getConfig()

// Only events in streamland are currently considered as online events
const STREAMLAND_COUNTRY_URL_CODE = '00'

const EventJsonLd = ({
  title,
  startDate,
  endDate,
  description,
  images,
  artists,
  venueName,
  venueAddress,
  venueRegion,
  venueCountry,
  venueCountryUrlCode,
  venueLocation,
  promoters,
  contentUrl,
  tickets,
}) => {
  let jsonld = {
    '@context': 'http://schema.org',
    '@type': 'MusicEvent',
    location: {
      '@type': 'Place',
      name: venueName,
      address: {
        '@type': 'PostalAddress',
        streetAddress: venueAddress,
        addressRegion: venueRegion,
        addressCountry: venueCountry,
      },
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: venueLocation?.latitude,
      longitude: venueLocation?.longitude,
    },
    name: title,
    startDate,
    description,
    endDate,
    image: images,
    eventAttendanceMode:
      venueCountryUrlCode === STREAMLAND_COUNTRY_URL_CODE
        ? 'https://schema.org/OnlineEventAttendanceMode'
        : 'https://schema.org/OfflineEventAttendanceMode',
    eventStatus: 'https://schema.org/EventScheduled',
    performer: artists.map((artist) => ({
      '@type': 'MusicGroup',
      name: artist.name,
      url: `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${artist.contentUrl}`,
    })),
    organizer: promoters.map((promoter) => ({
      '@type': 'Organization',
      name: promoter.name,
      url: `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${promoter.contentUrl}`,
    })),
  }

  if (arrayHasData(tickets)) {
    const offers = getOffers({ contentUrl, tickets })

    jsonld = { ...jsonld, offers }
  }

  return <JsonLdHeadScript properties={jsonld} scriptKey="jsonld-event" />
}

EventJsonLd.propTypes = {
  title: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  artists: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      contentUrl: PropTypes.string,
    })
  ).isRequired,
  venueName: PropTypes.string.isRequired,
  venueAddress: PropTypes.string,
  venueRegion: PropTypes.string.isRequired,
  venueCountry: PropTypes.string.isRequired,
  venueCountryUrlCode: PropTypes.string,
  venueLocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  promoters: PropTypes.array.isRequired,
  contentUrl: PropTypes.string,
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      validType: PropTypes.string,
      onSaleFrom: PropTypes.string,
      priceRetail: PropTypes.number,
      currency: PropTypes.shape({
        code: PropTypes.string,
      }),
    })
  ),
}

const TICKET_VALID_TYPE = {
  VALID: 'VALID',
  NOTYETONSALE: 'NOTYETONSALE',
  NOLONGERONSALE: 'NOLONGERONSALE',
  SOLDOUT: 'SOLDOUT',
  QUANTITYGREATERTHANTICKETSAVAILABLE: 'QUANTITYGREATERTHANTICKETSAVAILABLE',
}

const getAvailability = (tickets) => {
  if (
    tickets.every(
      (x) =>
        x.validType === TICKET_VALID_TYPE.NOLONGERONSALE ||
        x.validType === TICKET_VALID_TYPE.SOLDOUT
    )
  ) {
    return 'https://schema.org/SoldOut'
  }
  return 'https://schema.org/InStock'
}

const getOffers = ({ contentUrl, tickets }) => {
  const availability = getAvailability(tickets)

  const validTickets = tickets.filter(
    (x) => x.validType === TICKET_VALID_TYPE.VALID && !x.isAddOn
  )

  const ticket = arrayHasData(validTickets)
    ? validTickets.reduce((cheapestTicket, currentTicket) =>
        currentTicket.priceRetail < cheapestTicket.priceRetail
          ? currentTicket
          : cheapestTicket
      )
    : tickets[0]

  const offers = {
    '@type': 'Offer',
    name: ticket.title,
    category: 'https://schema.org/Product',
    url: `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${contentUrl}`,
    priceCurrency: ticket?.currency?.code,
    price: ticket?.priceRetail,
    validFrom: ticket?.onSaleFrom,
    availability,
  }

  return offers
}

export default EventJsonLd
