import PropTypes from 'prop-types'
import JsonLdHeadScript from './JsonLdHeadScript'

const VideoJsonLd = ({
  name,
  description,
  thumbnails,
  uploadDate,
  embedUrl,
}) => (
  <JsonLdHeadScript
    properties={{
      '@context': 'http://schema.org',
      '@type': 'VideoObject',
      name,
      description,
      thumbnailUrl: thumbnails,
      uploadDate,
      embedUrl,
    }}
    scriptKey="jsonld-video"
  />
)

VideoJsonLd.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  thumbnails: PropTypes.arrayOf(PropTypes.string.isRequired),
  uploadDate: PropTypes.string.isRequired,
  embedUrl: PropTypes.string.isRequired,
}

export default VideoJsonLd
