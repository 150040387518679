import { useMemo } from 'react'
import PropTypes from 'prop-types'
import getConfig from 'next/config'
import { JsonLdHeadScript } from 'components/generic/json-ld'

const { publicRuntimeConfig } = getConfig()

const BreadcrumbJsonLd = ({ links = [] }) => {
  const itemListElement = useMemo(
    () =>
      links.map(({ text, href, as }, i) => ({
        '@type': 'ListItem',
        position: i + 1,
        name: text,
        item: `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${as?.pathname || href}`, // Ensure url is valid for dynamic routes
      })),
    [links]
  )

  const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  }

  return <JsonLdHeadScript properties={jsonld} scriptKey="jsonld-breadcrumbs" />
}

BreadcrumbJsonLd.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ pathname: PropTypes.string }),
      ]).isRequired,
    }).isRequired
  ),
}

export default BreadcrumbJsonLd
