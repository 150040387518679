import PropTypes from 'prop-types'
import getConfig from 'next/config'
import JsonLdHeadScript from './JsonLdHeadScript'

import { publisher } from './shared'

const { publicRuntimeConfig } = getConfig()

const NewsArticleJsonLd = ({
  contentUrl,
  title,
  images = [],
  datePublished,
  dateModified = null,
  authorName,
  description,
  keywords,
  articleSection,
}) => {
  const url = `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${contentUrl}`

  const jsonld = {
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    url,
    headline: title,
    thumbnailUrl: images[0],
    image: images,
    datePublished,
    dateModified: dateModified || datePublished,
    author: {
      '@type': 'Person',
      name: authorName,
    },
    publisher,
    description,
    articleSection,
    keywords,
  }

  return <JsonLdHeadScript properties={jsonld} scriptKey="jsonld-article" />
}

NewsArticleJsonLd.defaultProps = {
  dateModified: null,
}

NewsArticleJsonLd.propTypes = {
  contentUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  datePublished: PropTypes.string.isRequired,
  dateModified: PropTypes.string,
  authorName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.array.isRequired,
  articleSection: PropTypes.string.isRequired,
}

export default NewsArticleJsonLd
