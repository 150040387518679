import PropTypes from 'prop-types'
import getConfig from 'next/config'
import CONTENT_LANGUAGES from 'lib/contentLanguages'
import JsonLdHeadScript from './JsonLdHeadScript'

import { publisher } from './shared'

const { publicRuntimeConfig } = getConfig()

const ReviewJsonLd = ({ review, keywords }) => (
  <JsonLdHeadScript
    properties={{
      '@context': 'http://schema.org',
      '@type': 'Review',
      itemReviewed: {
        '@type': 'MusicRelease',
        '@id': `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${review.contentUrl}`,
        image: review.imageUrl,
        name: review.title,
        recordLabel: review.labels.map((label) => ({
          '@type': 'Organization',
          '@id': `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${label.contentUrl}`,
          name: label.name,
        })),
        catalogNumber: review.catalogue,
        datePublished: review.releaseDate,
        author: review.artists.map((artist) => ({
          '@type': 'MusicGroup',
          name: artist.name,
          url: `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${artist.contentUrl}`,
        })),
      },
      name: review.title,
      author: {
        '@type': 'Person',
        name: review.author.name,
      },
      datePublished: review.date,
      inLanguage: CONTENT_LANGUAGES[review.language].iso.toUpperCase(),
      abstract: review.blurb,
      reviewBody: review.content,
      publisher,
      keywords,
    }}
    scriptKey="jsonld-article"
  />
)

ReviewJsonLd.propTypes = {
  review: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    contentUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    releaseDate: PropTypes.string,
    author: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    blurb: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    catalogue: PropTypes.string,
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        name: PropTypes.string.isRequired,
        contentUrl: PropTypes.string.isRequired,
      })
    ),
    artists: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        name: PropTypes.string.isRequired,
        contentUrl: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  keywords: PropTypes.array.isRequired,
}

export default ReviewJsonLd
