import { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { Box } from '@resident-advisor/design-system'
import { useThemeContext } from 'hooks/useThemeContext'
import { HeroImage } from 'components/generic/ips/item-components/HeroImage'
import opacity from 'themes/opacity'
import image from 'themes/image'
import { LayoutProps } from 'styled-system'

const HeaderWrapper = ({
  children,
  imageUrl,
  mobileImageUrl,
  bg,
  height,
  blur = false,
}: PropsWithChildren<HeaderWrapperProps>) => {
  const theme = useThemeContext()

  const imageProperties = blur ? { blur: image?.header?.blur } : {}

  if (imageUrl || mobileImageUrl) {
    const chosenMobileImage = mobileImageUrl || imageUrl

    return (
      <Wrapper height={height}>
        <Box opacity={blur ? opacity.blurHeader : 1}>
          <HeroImage
            imageUrl={imageUrl}
            imageUrlMobile={chosenMobileImage}
            imageProperties={imageProperties}
            loaded
          />
        </Box>
        <Box position="relative" height="100%">
          {children}
        </Box>
      </Wrapper>
    )
  }

  return (
    <Box bg={bg || theme.headerWrapper.backgroundColor} height={height}>
      {children}
    </Box>
  )
}

const Wrapper = styled(Box)`
  ${({ theme }) =>
    css`
      background-color: ${theme.headerWrapper.backgroundColor};
    `};

  background-position: center;
  background-size: cover;

  ${({ height }) =>
    css`
      height: ${height};
    `};

  position: relative;
`

type HeaderWrapperProps = {
  height: LayoutProps['height']
  imageUrl?: string
  mobileImageUrl?: string
  bg?: string
  blur: boolean
}

Wrapper.displayName = 'Wrapper'

export default HeaderWrapper
