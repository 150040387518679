import { PropsWithChildren } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Layout, Alignment } from '@resident-advisor/design-system'
import { light as lightTheme, dark as darkTheme, headers } from 'themes'
import useMenuColors from 'hooks/useMenuColors'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import HeaderContent from './HeaderContent'
import HeaderWrapper from './HeaderWrapper'
import { BreadcrumbLinkProps } from '../breadcrumb/Breadcrumb'

const Header = ({
  children,
  breadcrumb = null,
  tags,
  text,
  imageUrl,
  mobileImageUrl,
  light,
  bg,
  tall,
  dynamicFontSize,
  blur,
  ContentComponent = null,
  condensed = false,
  ...props
}: PropsWithChildren<HeaderProps>) => {
  useMenuColors(bg)
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)

  return (
    <header {...props}>
      <ThemeProvider theme={light ? lightTheme : darkTheme}>
        <HeaderWrapper
          height={(condensed && headers.condensed.height) || undefined}
          imageUrl={imageUrl}
          mobileImageUrl={mobileImageUrl}
          bg={bg}
          blur={blur}
        >
          {!condensed && (
            <FullHeightLayout>
              <HeaderContentWrapper
                alignItems={{
                  s: 'flex-start',
                  m: tall ? 'flex-end' : 'flex-start',
                }}
                mt={{
                  s: enableNewMainNav ? headers.default.mt : 0,
                  m: headers.default.mt,
                }}
                pt={tall ? headers.tall.pt : headers.default.pt}
                pb={tall ? headers.tall.pb : headers.default.pb}
              >
                <HeaderContent
                  breadcrumb={breadcrumb}
                  tags={tags}
                  text={text}
                  dynamicFontSize={dynamicFontSize}
                  Component={ContentComponent}
                />
              </HeaderContentWrapper>
            </FullHeightLayout>
          )}
          {children}
        </HeaderWrapper>
      </ThemeProvider>
    </header>
  )
}

const HeaderContentWrapper = styled(Alignment)`
  box-sizing: border-box;
`

const FullHeightLayout = styled(Layout)`
  height: 100%;
`

type TagProps = {
  href: string | { pathname: string; query: string | object }
  as?: string | { pathname: string; query: string | object }
  text: string
}

type HeaderProps = {
  text?: string | React.ReactNode
  imageUrl?: string
  mobileImageUrl?: string
  light?: boolean
  children?: React.ReactNode
  breadcrumb?: BreadcrumbLinkProps[]

  tags?: TagProps[]
  bg?: string
  tall?: boolean
  dynamicFontSize?: boolean
  blur?: boolean
  condensed?: boolean
  ContentComponent?: React.ReactNode
}

export default Header
