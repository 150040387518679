import { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { sizes as breakpoints } from '@resident-advisor/design-system'
import {
  generateSrcSets,
  generateSizes,
  defaultDimensions,
} from 'components/generic/responsive-image'

const HeroImage = ({
  imageUrl,
  imageUrlMobile,
  loaded,
  imageProperties = {},
}) => {
  const [hasError, setError] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const imgRef = useRef()

  let srcSet = generateSrcSets(imageUrl, defaultDimensions, imageProperties)

  let mobileSrcSet = generateSrcSets(
    imageUrlMobile,
    defaultDimensions,
    imageProperties
  )

  const sizes = generateSizes()

  useEffect(() => {
    if (imgRef.current?.complete && imgRef.current?.naturalWidth > 0) {
      setIsVisible(true)
    }
  }, [])

  if (!loaded) {
    srcSet = ''
    mobileSrcSet = ''
  }

  if (hasError) {
    return null
  }

  return (
    <picture>
      <source
        media={`(max-width: ${breakpoints.m}px)`}
        srcSet={mobileSrcSet}
        sizes={sizes}
      />
      <source
        media={`(min-width: ${breakpoints.m + 0.1}px)`}
        srcSet={srcSet}
        sizes={sizes}
      />
      <BaseImage
        ref={imgRef}
        srcSet={srcSet}
        sizes={sizes}
        onError={() => setError(true)}
        onLoad={() => setIsVisible(true)}
        isVisible={isVisible}
      />
    </picture>
  )
}

HeroImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageUrlMobile: PropTypes.string.isRequired,
  loaded: PropTypes.bool,
  imageProperties: PropTypes.object,
}

const BaseImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;

  ${({ isVisible }) =>
    isVisible
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
`

export { HeroImage }
