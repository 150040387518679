import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

export const publisher = {
  '@type': 'Organization',
  name: 'Resident Advisor',
  logo: {
    '@type': 'ImageObject',
    url: `${publicRuntimeConfig.PUBLIC_ASSETS_BASE_PATH}/images/ra-co-logo.jpeg`,
  },
}

export default { publisher }
